import React, { useContext } from 'react';

import { FormContext } from '../context';
import Label from '../label';

import './checkbox.css';

export default function Checkbox({ field }) {
  const { values, handleCheck } = useContext(FormContext);
  return (
    <label
      className={`checkbox${values[field.id] ? ' active' : ''}`}
      htmlFor={field.id}
    >
      <input
        onChange={handleCheck}
        {...field}
        style={{
          display: `none`,
          width: `auto`,
        }}
      />
      <Label label={field.label} required={field.required} />
    </label>
  );
}
